.sidebar {
     background-color: #0a1929;
    height: 100vh;
    padding: 5px;
    border-radius: 10px;
    z-index: 20;
}

.imageDimensions {
    background-color: rgba(255, 255, 255, 0.24);
}