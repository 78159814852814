 
#toolbar * {
    margin-bottom: 6px;
    text-align: left;
}

#toolbar label {
    font-size: 12px;
}


#toolbar input {
    width: 100%;
    font-size: 12px;
}

#toolbar{
    background-color:#a1a1a1 !important;
    border-radius: 1px dotted white;
    font-size: 10px;
    padding: 5px;
   text-align: left;
   color: #000000;
}
.active.glowNew{
  border: 2px dotted #f1f1f1 !important;
}

#toolbar button{
    border-radius: 1px dotted white;
}

.controls {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
  }
  
  .tools-button {
    height: 3em;
    width: 6em;
    margin: 1em;
    font-weight: bold;
    font-size: 0.5em;
    text-transform: uppercase;
    cursor: pointer;
    color: white;
    border: 1px solid white;
    background-color: black;
  }
 /* custom drawing board */
  .drawing-board{
    background-color: #ffffff6a;
    color: White;
    height: auto;
    width: 100vw ;
    text-align: center;
    justify-content: center;
    justify-items: center;
  }
  .drawing-board #toolbar{
    background-color: #554444;
    color: #202020;
  }
  .drawing-board .layers-sidebar{
    background-color:#2b2121 ;
    text-align: center;
    justify-content: center;

  }
  .drawing-board .layers-sidebar button{
    background-color: #2b2121;
    color: #ffffff;
    border: 2px dotted #ffffff;
    background-color:#554444 ;
    border-radius: 1px dotted white;
    font-size: 14px;
    width: 100%;
    padding: 5px;
    
  }
  .drawing-board label{
    margin: auto;
    background-color: #000000;
    font-weight: 800;
    border: 1px dotted #b3b3b3;
    color: #b3b3b3;
    text-align: center;
  }
  .drawing-board input{
    margin: auto;
    background-color: #b3b3b3;
    font-weight: 800;
    border: 1px dotted #000000;
    color: #000000;
    text-align: center;
  }

  #fileslistbtn{
    position: absolute;
    top: 20vh;
    left: 0;
    width: 0;
    color: #000000;
    background-color: #000000;
    z-index: 10;
  }
  div[role=presentation]{
    justify-content: center;
  }
  /* #filelistnav{
    position: fixed;
    top: 20vh;
    left: 0;
    width: 0;
    background-color: #000000;
    z-index: 50;
    display: none;
  }
  #filelistnav.active{
    width: 40vw;
    display: block;
    transform: rotate3d();
  } */
  /* The side navigation menu */
.filelistnav {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 999; /* Stay on top */
    top: 80px; /* Stay at the top */
    left: 0;
    background-color: #111; /* Black*/
    color: #b3b3b3;
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    overflow-y: auto;
    text-align: left;
  }
  
  /* The navigation menu links */
  .filelistnav button {
    width: 100%;
    text-decoration: none;
    font-size: 20px;
    border: 1px dotted f1f1f1;
    color: #818181;
    display: block;
    transition: 0.3s;
    text-align: left;
  }
  
  /* When you mouse over the navigation links, change their color */
  .filelistnav button:hover {
    color: #554444;
    background-color: #f1f1f1;
  }
  
  /* Position and style the close button (top right corner) */
  .filelistnav .closebtn {
    width: auto;
    color: #f1f1f1;
    background-color: #554444;
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  #loading {
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 50%;
    z-index: 9999;
    background: #818181a5;
    color: white;
  }
  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
    .filelistnav {padding-top: 15px;}
    .filelistnav a {font-size: 18px;}
  }