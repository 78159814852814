.items:hover {
    background-color: rgba(190, 190, 190, 0.336);
}

/* .popup, .image-container{
    width: 100% !important;
     height: 100vh !important;
 } */
/* .image-container {
    display: flex !important;
    flex-wrap: wrap !important;
    flex-direction: row !important;
}
.image-container .image-holder-full{
    flex: 1 0;
    width: 50% !important;
     height: auto !important;
}
.image-container .control-panel{
    flex: 0 1;
   
} */