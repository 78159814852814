@import url("//fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body{
   width: 100vw;
  overflow-y: auto !important;
  font-family: 'monospace';
}
 

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


html,body {
    color: radial-gradient(#161c24 0%, #021025 100%);
   }

  .eachOne {
    margin-left: 25px;
  }

  .imageBackground {
    position: fixed; 
  top: 0; 
  left: 0; 
	
  /* Preserve aspet ratio */
  min-width: 100%;
  min-height: 100%;
 
}
 
  .landingButtons {
    display: flex;
    flex-direction: row;
  }

  .landingNavMenu {
    font-style: normal;
    z-index: 1;
    font-weight: "bold";
    animation: glow 2s ease-in-out infinite alternate;
    text-align: center;
  }

  .landingNavMenu:hover {
    color: rgb(190, 190, 190);
    animation: glow 2s ease-in-out infinite alternate;
    cursor: pointer;
    /* background-color: #2b2b2b98; */
  }

  .nice{
    position: absolute;
    background: transparent;
    color: white;
    width: 250px;
    height: 50px;
    font-size: 2em;
    border-radius: 5px;
    top: 70vh;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    transition: .3s;
    animation: glow 2s ease-in-out infinite alternate;
  }
  
  .nice:hover{
    background-color: rgba(365,365,365,0.5);
    cursor: grab/pointer;
    color: #ffffff;
    transition: .3s;
  }
  
.title {
     font-style: normal;
    text-align: center;
    color: #FFF;
     align-items: center;
    justify-content: center;
    letter-spacing: 1px;
    z-index: 3;
  }
  
  h1 {
    margin: 0;
    font-size: 55px;
    font-style: normal;
    grid-column: 1;
    grid-row: 1;
    font-weight: 700;
    font-family: "Montserrat Bold";
    animation: glow 2s ease-in-out infinite alternate;
    text-align: center;
  }

  h3 {
    margin: 0;
    font-size: 20px;
    font-style: normal;
    grid-column: 1;
    grid-row: 1;
    font-weight: 400;
    font-family: "Montserrat Bold";
    animation: glow 2s ease-in-out infinite alternate;
    text-align: "center";
  }
  
  @keyframes glow {
    from {
      text-shadow: 0 0 20px #2d9da9;
    }
    to {
      text-shadow: 0 0 30px #34b3c1, 0 0 10px #4dbbc7;
    }
  }

  .glitch {
    font-size: 5rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
      0.025em 0.04em 0 #fffc00;
    animation: glitch 725ms infinite;
  }

  .homepageContent {
    font-family: "Montserrat Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #fff;
  }
  
  .glitch span {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .glitch span:first-child {
    animation: glitch 500ms infinite;
    clip-path: polygon(0 0, 100% 0, 100% 35%, 0 35%);
    transform: translate(-0.04em, -0.03em);
    opacity: 0.75;
  }
  
  .glitch span:last-child {
    animation: glitch 375ms infinite;
    clip-path: polygon(0 65%, 100% 65%, 100% 100%, 0 100%);
    transform: translate(0.04em, 0.03em);
    opacity: 0.75;
  }
  
  @keyframes glitch {
    0% {
      text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
        0.025em 0.04em 0 #fffc00;
    }
    15% {
      text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
        0.025em 0.04em 0 #fffc00;
    }
    16% {
      text-shadow: -0.05em -0.025em 0 #00fffc, 0.025em 0.035em 0 #fc00ff,
        -0.05em -0.05em 0 #fffc00;
    }
    49% {
      text-shadow: -0.05em -0.025em 0 #00fffc, 0.025em 0.035em 0 #fc00ff,
        -0.05em -0.05em 0 #fffc00;
    }
    50% {
      text-shadow: 0.05em 0.035em 0 #00fffc, 0.03em 0 0 #fc00ff,
        0 -0.04em 0 #fffc00;
    }
    99% {
      text-shadow: 0.05em 0.035em 0 #00fffc, 0.03em 0 0 #fc00ff,
        0 -0.04em 0 #fffc00;
    }
    100% {
      text-shadow: -0.05em 0 0 #00fffc, -0.025em -0.04em 0 #fc00ff,
        -0.04em -0.025em 0 #fffc00;
    }
  }  
  
  .maintxt { max-height: 300px !important;
    position: relative;
  }
.maintxt .overlay-text {
  color: black;
   position: absolute;
   max-width: 100%;
   padding: 5px;
   bottom: 0%;
   left: 0%;
   right:0%;
   background-color: #ffffff;
   font-size: auto;
  }
  section{
    z-index: 3;
  }
  .inner-container{
    z-index: 3;
    width: 100%;
    position: relative;
    height: fit-content !important;
   }
   .slider, .image-gallery-slide-wrapper    {
    border-style: none;
    height: 400px !important;
    width: 80% !important;
   }
   .image-gallery-slide   {
    height: 100% !important;
    width: 400px !important;
   }
/* area */
.hero-area{
   width: 100%;
   height:50vh;
   display: block;
     position: relative;
}
.loading-now{
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 10;
  background-color: #492b2b03 !important;
}

.homepage1-area{
  position: relative;
  width: 100%;
  display: block;
  height:50vh;
   box-shadow: #ee7752;
   background: #492b2b03; 
}
.sub-menu-below{
  position: relative;
  background: #492b2b;  
  background: linear-gradient(-45deg, #bf6549, #721e3e, #447c90, #23d5ab);
background-size: 400% 400%;
animation: gradient 15s ease infinite; 
  width: 100%;
  display: block;
  height:auto;
  z-index: 20;
   box-shadow: #ee7752;
   background: #492b2b03; 
}

#navbartop, .navbar {
  z-index: 10;
}
#general-background{
  position: relative;
  background: #492b2b;  
  background: linear-gradient(-45deg, #461607, #4f0a25, #0a3849, #0d4437);
background-size: 400% 400%;
animation: gradient 15s ease infinite; 
  width: 100%;
  height: 100%;
  z-index: 0;
  box-shadow: #511e0e;
}
#general-inner-container{
  z-index: 2;
  display: inline-block; 
  width: 100%;
  height: auto;
  background: #492b2b09;
  box-shadow: 20px 20px 16px #271c19;
}
.area{
  display: block;
  position: relative;
  margin: auto;
   width: 100% !important;
   padding: 5%;
  height: auto;
  z-index: 3;
  margin-bottom: 10vh !important;
}

.invest{
  background: #772e2e;  
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
background-size: 400% 400%;
animation: gradient 15s ease infinite; 
  box-shadow: #271c19;
  margin: auto;
}

.circles{
  position: fixed;
  top: 0;
  left: 0;
  z-index:1;
  width: 100%;
  height: 100%;
 }

.circles li{
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -50px;
  
}

.circles li:nth-child(1){
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.circles li:nth-child(2){
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3){
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4){
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5){
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6){
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7){
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8){
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9){
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10){
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}



@keyframes animate {

  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }

  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }

}


.model-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
   min-width: 80vw;
  height: 50vh;
  overflow: auto;
  border: "2px solid #000";
  border-radius: 10px;
  padding: 20px;
   box-shadow: 20px;
  background: #352c2c;
  opacity: 0.7;
  color: #fff
};
 
.nfts-wrap {
  overflow: hidden;
  position: relative;
}

.nfts-bg {
  opacity: 0.6;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

.nfts-content {
  position: relative;
}
.glowNew{
  background-blend-mode: red;
  bottom: 0;
    color: white;
   font-weight: 400;
  animation: 'glow 2s ease-in-out infinite alternate';

}
a{
  text-decoration: none !important;

}


body .awssld__content > img{
  object-fit: contain !important;
}